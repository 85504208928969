export { IconMenu } from "./icon-menu";
export { IconClose } from "./icon-close";
export { IconArrowDropdown } from "./icon-arrow-dropdown";
export { IconLocation } from "./icon-location";
export { IconReceipt } from "./icon-receipt";
export { IconDialpadAlt } from "./icon-dialpad-alt";
export { IconUserCircle } from "./icon-user-circle";
export { IconInfoCircle } from "./icon-info-circle";
export { IconCreditCard } from "./icon-credit-card";
export { IconArrowLeft } from "./icon-arrow-left";
export { IconArrowRight } from "./icon-arrow-right";
export { IconSubtract } from "./icon-subtract";
export { IconStore } from "./icon-store";
export { IconPerson } from "./icon-person";
export { IconCall } from "./icon-call";
export { IconMail } from "./icon-mail";
export * from "./social-icons";
