import {
  Button,
  Divider,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";

export type ReplacementsMap = Record<
  string,
  (
    domNode: Element,
    options: HTMLReactParserOptions
  ) => JSX.Element | Element | null
>;

interface ParserProps {
  data?: string;
  redefine?: ReplacementsMap;
}

export const Parser = (props: ParserProps) => {
  const { data, redefine } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let replacementsMap: ReplacementsMap = {};
  replacementsMap.a = (domNode: Element, options: HTMLReactParserOptions) => {
    const href = domNode.attribs.href;

    return <Link href={href}>{domToReact(domNode.children, options)}</Link>;
  };

  replacementsMap.p = (domNode: Element, options: HTMLReactParserOptions) => {
    return (
      <Typography
        mb={1}
        variant="text6"
        sx={{
          "&:last-child": {
            marginBottom: 0,
          },
        }}
      >
        {domToReact(domNode.children, options)}
      </Typography>
    );
  };

  replacementsMap.h2 = (domNode: Element, options: HTMLReactParserOptions) => {
    return (
      <Typography variant={isMobile ? "headline6" : "headline2"}>
        {domToReact(domNode.children, options)}
      </Typography>
    );
  };

  replacementsMap.h3 = (domNode: Element, options: HTMLReactParserOptions) => {
    return (
      <Typography variant={isMobile ? "headline6" : "headline3"}>
        {domToReact(domNode.children, options)}
      </Typography>
    );
  };

  replacementsMap.h4 = (domNode: Element, options: HTMLReactParserOptions) => {
    return (
      <Typography variant={isMobile ? "headline6" : "headline4"}>
        {domToReact(domNode.children, options)}
      </Typography>
    );
  };

  replacementsMap.hr = () => {
    return <Divider sx={{ mb: 2 }} />;
  };

  replacementsMap["link-button"] = (
    domNode: Element,
    options: HTMLReactParserOptions
  ) => {
    const link = domNode.attribs["data-link"];

    return (
      <Button
        size="small"
        href={link}
        fullWidth={true}
        target="_blank"
        variant="outlined"
      >
        {domToReact(domNode.children, options)}
      </Button>
    );
  };

  replacementsMap = {
    ...replacementsMap,
    ...redefine,
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element) {
        if (replacementsMap[domNode.name]) {
          return replacementsMap[domNode.name](domNode, options);
        }
        return domNode;
      }
    },
  };

  if (!data) {
    return null;
  }

  return <>{parse(data, options)}</>;
};
