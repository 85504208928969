import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconClose = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0696 9L13.2785 12.2089C13.5738 12.5042 13.5738 12.9831 13.2785 13.2785C12.9831 13.5738 12.5042 13.5738 12.2089 13.2785L9 10.0696L5.79114 13.2785C5.49578 13.5738 5.01689 13.5738 4.72153 13.2785C4.42616 12.9831 4.42616 12.5042 4.72153 12.2089L7.93038 9L4.72153 5.79114C4.42616 5.49578 4.42616 5.01689 4.72153 4.72153C5.01689 4.42616 5.49578 4.42616 5.79114 4.72153L9 7.93038L12.2089 4.72153C12.5042 4.42616 12.9831 4.42616 13.2785 4.72153C13.5738 5.01689 13.5738 5.49578 13.2785 5.79114L10.0696 9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
