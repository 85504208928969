import { Config } from "../types";

const config: Config = {
  apiUrl: process.env.API_URL,
  currency: "VND",
  defaultLocale: "vi-VN",
  locales: ["en", "vi-VN"],
  cdnUrl: process.env.CDN_PATH,
  countryCode: process.env.COUNTRY_CODE,
  faviconUrl: `${process.env.CDN_PATH}/favicons/favicon.ico`,
  logoUrl: `${process.env.CDN_PATH}/logos/full.svg`,
  logoUrlLight: `${process.env.CDN_PATH}/logos/full-light.svg`,
  logoUrlShort: `${process.env.CDN_PATH}/logos/short.svg`,
  openGraphLogoUrl: `${process.env.CDN_PATH}/logos/openGraphLogo.png`,
  title: "Myvay",
  googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
  phonePrefixInternational: "+84",
  phonePrefixNational: "02",
  // TODO: fill up
  externalLinks: {
    googlePlayBadge: "#",
    whatsApp: "#",
    viber: "#",
    payEmail: "#",
    supportEmail: "#",
  },
  domain: "todo",
  bankAccountHolderName: "",
  chatbotClientKey: "",
};

export default config;
