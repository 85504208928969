import { Box, Link } from "@mui/material";

import { SocialLinkType } from "@/shared/api/common-api";
import {
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTiktok,
  IconTwitter,
  IconWhatsapp,
} from "@/shared/ui";

type SocialMediaProps = {
  type: SocialLinkType;
  link: string;
};

export const SocialLink = ({ type, link }: SocialMediaProps) => {
  const SOCIAL_MEDIA_ICONS: Record<SocialLinkType, React.ReactNode> = {
    facebook: <IconFacebook />,
    instagram: <IconInstagram />,
    linkedin: <IconLinkedin />,
    tiktok: <IconTiktok />,
    twitter: <IconTwitter />,
    whatsapp: <IconWhatsapp />,
  };

  return (
    <Box
      component={Link}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: "neutral.50",

        svg: {
          width: 30,
          height: 30,
        },

        "&:hover": {
          svg: {
            color: "primary.main",
          },
        },
      }}
    >
      {SOCIAL_MEDIA_ICONS[type]}
    </Box>
  );
};
