import { Breakpoint, Typography, TypographyProps } from "@mui/material";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type ResponsiveTypographyProps = {
  variantMap: PartialRecord<Breakpoint, TypographyProps["variant"]>;
  sx?: Record<string, any>;
} & Omit<TypographyProps, "sx">;

export const ResponsiveTypography = (props: ResponsiveTypographyProps) => {
  const { variantMap, children, sx = {}, ...otherProps } = props;

  const { display, ...otherSx } = sx;
  const variantMapEntries = Object.entries(variantMap);

  return (
    <>
      {variantMapEntries.map((entry, index, array) => {
        const nextEntry = array[index + 1];
        const prevEntry = array[index - 1];
        const displayProp = {
          [entry[0]]: display || "block",
        };
        if (Array.isArray(prevEntry)) {
          displayProp[prevEntry[0]] = "none";
        }
        if (Array.isArray(nextEntry)) {
          displayProp[nextEntry[0]] = "none";
        }

        return (
          <Typography
            key={entry[0]}
            variant={entry[1]}
            sx={{
              display: displayProp,
              ...otherSx,
            }}
            {...otherProps}
          >
            {children}
          </Typography>
        );
      })}
    </>
  );
};
