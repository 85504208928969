import { Box, BoxProps } from "@mui/material";
import { ReactElement, useEffect, useRef } from "react";

import { checkVisible } from "@/shared/lib";

type HorizontalSliderProps = {
  spacing: number;
  items: ReactElement[];
} & BoxProps;

export const HorizontalSlider = (props: HorizontalSliderProps) => {
  const { spacing, items, ...boxProps } = props;

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const onScroll = () => {
      if (ref.current) {
        const isVisible = checkVisible(ref.current);
        if (isVisible) {
          ref.current?.focus();
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Box
      mx={-spacing}
      sx={{
        overflow: "auto",
        whiteSpace: "nowrap",
        display: "flex",
        flex: "none",
        scrollSnapType: "x mandatory",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        outline: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "& *": {
          whiteSpace: "initial",
        },
      }}
      ref={ref}
      tabIndex={0}
      {...boxProps}
    >
      {items?.map((item, index) => (
        <Box
          key={index}
          ml={spacing}
          mr={index === items.length - 1 ? spacing : 0}
          sx={{
            display: "inline-block",
            scrollSnapAlign: "center",
          }}
        >
          {item}
        </Box>
      ))}
    </Box>
  );
};
