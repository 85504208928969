import { Box } from "@mui/material";

import { useConfig } from "@/shared/hooks";

export type TLogoVariant = "primary" | "light" | "short";

export const Logo = ({
  height = 38,
  width = 165,
  variant = "primary",
}: {
  height?: number;
  width?: string | number;
  variant?: TLogoVariant;
}) => {
  const config = useConfig();

  if (!config) {
    return null;
  }

  const logoVariantsMap: Record<TLogoVariant, string> = {
    light: config.logoUrlLight,
    primary: config.logoUrl,
    short: config.logoUrlShort,
  } as const;

  const src = logoVariantsMap[variant];

  return (
    <Box
      alt={config.title}
      component="img"
      display="block"
      height={height}
      src={src}
      sx={{
        maxWidth: "100%",
      }}
      width={width}
    />
  );
};
