import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconWhatsapp = (props: SvgIconProps) => {
  return (
    <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8125 6.13757C22.6665 4.97998 21.3015 4.06216 19.797 3.43764C18.2926 2.81312 16.6789 2.4944 15.05 2.50007C8.225 2.50007 2.6625 8.06257 2.6625 14.8876C2.6625 17.0751 3.2375 19.2001 4.3125 21.0751L2.5625 27.5001L9.125 25.7751C10.9375 26.7626 12.975 27.2876 15.05 27.2876C21.875 27.2876 27.4375 21.7251 27.4375 14.9001C27.4375 11.5876 26.15 8.47507 23.8125 6.13757ZM15.05 25.1876C13.2 25.1876 11.3875 24.6876 9.8 23.7501L9.425 23.5251L5.525 24.5501L6.5625 20.7501L6.3125 20.3626C5.28468 18.7213 4.73893 16.8241 4.7375 14.8876C4.7375 9.21257 9.3625 4.58757 15.0375 4.58757C17.7875 4.58757 20.375 5.66257 22.3125 7.61257C23.2719 8.56753 24.0321 9.7034 24.5492 10.9544C25.0664 12.2053 25.33 13.5465 25.325 14.9001C25.35 20.5751 20.725 25.1876 15.05 25.1876ZM20.7 17.4876C20.3875 17.3376 18.8625 16.5876 18.5875 16.4751C18.3 16.3751 18.1 16.3251 17.8875 16.6251C17.675 16.9376 17.0875 17.6376 16.9125 17.8376C16.7375 18.0501 16.55 18.0751 16.2375 17.9126C15.925 17.7626 14.925 17.4251 13.75 16.3751C12.825 15.5501 12.2125 14.5376 12.025 14.2251C11.85 13.9126 12 13.7501 12.1625 13.5876C12.3 13.4501 12.475 13.2251 12.625 13.0501C12.775 12.8751 12.8375 12.7376 12.9375 12.5376C13.0375 12.3251 12.9875 12.1501 12.9125 12.0001C12.8375 11.8501 12.2125 10.3251 11.9625 9.70007C11.7125 9.10007 11.45 9.17507 11.2625 9.16257H10.6625C10.45 9.16257 10.125 9.23757 9.8375 9.55007C9.5625 9.86257 8.7625 10.6126 8.7625 12.1376C8.7625 13.6626 9.875 15.1376 10.025 15.3376C10.175 15.5501 12.2125 18.6751 15.3125 20.0126C16.05 20.3376 16.625 20.5251 17.075 20.6626C17.8125 20.9001 18.4875 20.8626 19.025 20.7876C19.625 20.7001 20.8625 20.0376 21.1125 19.3126C21.375 18.5876 21.375 17.9751 21.2875 17.8376C21.2 17.7001 21.0125 17.6376 20.7 17.4876Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
