import { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";

import { Parser, ReplacementsMap } from "@/shared/ui/parser";

interface NotStyledParserProps {
  data: string;
}

export const NotStyledParser = (props: NotStyledParserProps) => {
  const replacementFunc = (
    domNode: Element,
    options: HTMLReactParserOptions
  ) => {
    return (
      <span
        style={{
          display: "block",
        }}
      >
        {domToReact(domNode.children, options)}
      </span>
    );
  };
  const textReplacementsMap: ReplacementsMap = {
    p: replacementFunc,
    h1: replacementFunc,
    h2: replacementFunc,
    h3: replacementFunc,
    h4: replacementFunc,
    h5: replacementFunc,
    h6: replacementFunc,
  };

  return <Parser data={props.data} redefine={textReplacementsMap} />;
};
