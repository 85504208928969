import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconMenu = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M3 11h18a1 1 0 0 0 0-2H3a1 1 0 0 0 0 2Zm18 2H3a1 1 0 0 0 0 2h18a1 1 0 0 0 0-2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
