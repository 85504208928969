import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconArrowLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="12" viewBox="0 0 17 12" fill="none" {...props}>
      <path
        d="M0.467716 5.46968C0.174822 5.76257 0.174822 6.23744 0.467716 6.53034L5.24069 11.3033C5.53358 11.5962 6.00845 11.5962 6.30135 11.3033C6.59424 11.0104 6.59424 10.5355 6.30135 10.2426L2.05871 6.00001L6.30135 1.75737C6.59424 1.46447 6.59424 0.989599 6.30135 0.696706C6.00845 0.403812 5.53358 0.403812 5.24069 0.696705L0.467716 5.46968ZM16.7264 5.25001L0.998046 5.25001L0.998046 6.75001L16.7264 6.75001L16.7264 5.25001Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
