import { alpha } from "@mui/material";
import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from "@mui/material/styles";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography";
import { CSSProperties } from "react";

export interface CustomColor {
  0: string;
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  95: string;
  96?: string;
  99: string;
  100: string;
}

declare module "@mui/material/styles" {
  export interface TypographyVariants {
    headline1: CSSProperties;
    headline2: CSSProperties;
    headline3: CSSProperties;
    headline4: CSSProperties;
    headline5: CSSProperties;
    headline6: CSSProperties;
    headline7: CSSProperties;
    headline8: CSSProperties;
    headline9: CSSProperties;
    headline10: CSSProperties;
    text1: CSSProperties;
    text2: CSSProperties;
    text3: CSSProperties;
    text4: CSSProperties;
    text5: CSSProperties;
    text6: CSSProperties;
    text7: CSSProperties;
    text8: CSSProperties;
  }

  // allow configuration using `createTheme`
  export interface TypographyVariantsOptions {
    headline1?: CSSProperties;
    headline2?: CSSProperties;
    headline3?: CSSProperties;
    headline4?: CSSProperties;
    headline5?: CSSProperties;
    headline6?: CSSProperties;
    headline7?: CSSProperties;
    headline8?: CSSProperties;
    headline9?: CSSProperties;
    headline10?: CSSProperties;
    text1?: CSSProperties;
    text2?: CSSProperties;
    text3?: CSSProperties;
    text4?: CSSProperties;
    text5?: CSSProperties;
    text6?: CSSProperties;
    text7?: CSSProperties;
    text8?: CSSProperties;
  }

  export interface PaletteColor extends CustomColor {}

  export interface Color extends CustomColor {}

  export interface Palette extends CustomPalette {}

  export interface PaletteOptions extends CustomPaletteOptions {}

  export interface CommonColors {
    background: string;
    error: string;
    errorContainer: string;
    onBackground: string;
    onError: string;
    onErrorContainer: string;
    onPrimary: string;
    onPrimaryContainer: string;
    onSecondary: string;
    onSecondaryContainer: string;
    onSurface: string;
    onSurfaceVariant: string;
    onTertiary: string;
    onTertiaryContainer: string;
    outline: string;
    primary: string;
    primaryContainer: string;
    secondary: string;
    secondaryContainer: string;
    surface: string;
    surface1: string;
    surface2: string;
    surface3: string;
    surface4: string;
    surface5: string;
    surfaceVariant: string;
    tertiary: string;
    tertiaryContainer: string;
    inverseSurface: string;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  export interface TypographyPropsVariantOverrides {
    headline1: true;
    headline2: true;
    headline3: true;
    headline4: true;
    headline5: true;
    headline6: true;
    headline7: true;
    headline8: true;
    headline9: true;
    headline10: true;
    text1: true;
    text2: true;
    text3: true;
    text4: true;
    text5: true;
    text6: true;
    text7: true;
    text8: true;
  }
}

export interface CustomPalette {
  primary: PaletteColor;
  secondary: PaletteColor;
  tertiary: PaletteColor;
  neutral: PaletteColor;
  neutralVariant: PaletteColor;
}

export interface CustomPaletteOptions {
  primary?: PaletteColorOptions;
  secondary?: PaletteColorOptions;
  tertiary?: PaletteColorOptions;
  neutral?: PaletteColorOptions;
  neutralVariant?: PaletteColorOptions;
}

declare module "@mui/material" {
  export interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    neutral: true;
    neutralVariant: true;
  }
}

export const gray: CustomColor = {
  0: "#000000",
  10: "#0f131a",
  20: "#2a2d34",
  30: "#41454d",
  40: "#595d66",
  50: "#737780",
  60: "#8e9299",
  70: "#aaadb3",
  80: "#c6c8cc",
  90: "#e1e3e6",
  95: "#f0f1f2",
  96: "#f5f5f5",
  99: "#fbfbfc",
  100: "#ffffff",
};

export const error: CustomColor = {
  0: "#000000",
  10: "#41000c",
  20: "#690005",
  30: "#93000a",
  40: "#ef3030",
  50: "#de3730",
  60: "#ff5449",
  70: "#ff897d",
  80: "#ffb4ab",
  90: "#ffc1c0",
  95: "#ffedea",
  99: "#fffbff",
  100: "#ffffff",
};

const primary: CustomColor = {
  0: "#000000",
  10: "#7e3524",
  20: "#a84a36",
  30: "#d16047",
  40: "#fb7659",
  50: "#fc917a",
  60: "#fdad9b",
  70: "#fdc8bd",
  80: "#fed6cd",
  90: "#fee4de",
  95: "#fee4de",
  99: "#fee4de",
  100: "#ffffff",
};

const secondary: CustomColor = {
  0: "#000000",
  10: "#7e2424",
  20: "#a83636",
  30: "#d14747",
  40: "#fb5959",
  50: "#fc7a7a",
  60: "#fd9b9b",
  70: "#fdbdbd",
  80: "#fecdcd",
  90: "#fedede",
  95: "#ffeeee",
  99: "#ffeeee",
  100: "#ffffff",
};

const tertiary: CustomColor = {
  0: "#000000",
  10: "#101966",
  20: "#192599",
  30: "#2132cc",
  40: "#293eff",
  50: "#5465ff",
  60: "#7f8bff",
  70: "#a9b2ff",
  80: "#bfc5ff",
  90: "#d4d8ff",
  95: "#eaecff",
  99: "#fafaff",
  100: "#ffffff",
};

export const core: ThemeOptions = createTheme({
  palette: {
    primary: {
      ...primary,
      contrastText: primary[100],
      dark: primary[30],
      light: primary[50],
      main: primary[40],
    },
    secondary: {
      ...secondary,
      contrastText: secondary[100],
      dark: secondary[30],
      light: secondary[50],
      main: secondary[40],
    },
    tertiary: {
      ...tertiary,
      contrastText: tertiary[100],
      dark: tertiary[30],
      light: tertiary[50],
      main: tertiary[40],
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },

    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      tertiary: tertiary[40],
      tertiaryContainer: tertiary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3:
        "linear-gradient(0deg, rgba(19, 153, 0, 0.04), rgba(19, 153, 0, 0.04)), #f4f4f4",
      surface4:
        "linear-gradient(0deg, rgba(19, 153, 0, 0.07), rgba(19, 153, 0, 0.07)), #f4f4f4",
      surface5:
        "linear-gradient(0deg, rgba(19, 153, 0, 0.1), rgba(19, 153, 0, 0.1)), #f4f4f4",
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
    neutral: {
      ...gray,
      contrastText: gray[100],
      dark: gray[30],
      light: gray[50],
      main: gray[40],
    },
    neutralVariant: {
      ...gray,
      contrastText: gray[100],
      dark: gray[30],
      light: gray[50],
      main: gray[40],
    },
  },
  shadows: [
    "none",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
    "0px 4px 21px 0px rgba(0, 0, 0, 0.04)",
  ],
  shape: {
    borderRadius: 8,
  },
  typography: {
    headline1: { fontWeight: 800, fontSize: "3.125rem", lineHeight: "120%" },
    headline2: { fontWeight: 800, fontSize: "2.5rem", lineHeight: "120%" },
    headline3: { fontWeight: 800, fontSize: "2rem", lineHeight: "120%" },
    headline4: { fontWeight: 800, fontSize: "1.75rem", lineHeight: "130%" },
    headline5: { fontWeight: 700, fontSize: "1.375rem", lineHeight: "130%" },
    headline6: { fontWeight: 700, fontSize: "1.25rem", lineHeight: "130%" },
    headline7: { fontWeight: 700, fontSize: "1.125rem", lineHeight: "130%" },
    headline8: { fontWeight: 700, fontSize: "1rem", lineHeight: "130%" },
    headline9: { fontWeight: 700, fontSize: "0.875rem", lineHeight: "130%" },
    headline10: { fontWeight: 700, fontSize: "0.75rem", lineHeight: "130%" },

    text1: { fontWeight: 400, fontSize: "2rem", lineHeight: "120%" },
    text2: { fontWeight: 400, fontSize: "1.75rem", lineHeight: "130%" },
    text3: { fontWeight: 400, fontSize: "1.375rem", lineHeight: "130%" },
    text4: { fontWeight: 400, fontSize: "1.25rem", lineHeight: "140%" },
    text5: { fontWeight: 400, fontSize: "1.125rem", lineHeight: "140%" },
    text6: { fontWeight: 400, fontSize: "1rem", lineHeight: "140%" },
    text7: { fontWeight: 400, fontSize: "0.875rem", lineHeight: "140%" },
    text8: { fontWeight: 400, fontSize: "0.75rem", lineHeight: "100%" },

    fontFamily: "inherit",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1244,
      xl: 1436,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          textDecoration: "none",
          transition: theme.transitions.create(["all"], {
            duration: theme.transitions.duration.short,
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",
        variant: "contained",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          fontWeight: 700,
          fontSize: "1rem",
          lineHeight: "130%",
          textTransform: "none",
        }),
        containedPrimary: ({ theme }) => ({
          background: theme.palette.primary[40],
          "&:hover": {
            background: theme.palette.neutral[20],
          },
        }),
        containedSecondary: ({ theme }) => ({
          background: theme.palette.neutral[100],
          color: theme.palette.neutral[20],
          "&:hover": {
            background: theme.palette.neutral[20],
            borderColor: theme.palette.neutral[20],
            color: theme.palette.neutral[100],
          },
        }),
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.neutral[90],
          color: theme.palette.neutral[20],
          "&:hover": {
            background: theme.palette.neutral[20],
            borderColor: theme.palette.neutral[20],
            color: theme.palette.neutral[100],
          },
          "&:active": {
            background: theme.palette.primary[40],
            borderColor: theme.palette.primary[40],
            color: theme.palette.neutral[100],
          },
        }),
        outlinedSecondary: ({ theme }) => ({
          borderColor: theme.palette.neutral[90],
          color: theme.palette.neutral[100],
          "&:hover": {
            background: theme.palette.neutral[20],
            borderColor: theme.palette.neutral[20],
            color: theme.palette.neutral[100],
          },
          "&:active": {
            background: theme.palette.secondary[40],
            borderColor: theme.palette.secondary[40],
            color: theme.palette.neutral[100],
          },
        }),
        sizeSmall: ({ theme }) => ({
          paddingTop: theme.spacing(1.25),
          paddingBottom: theme.spacing(1.25),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }),
        sizeMedium: ({ theme }) => ({
          paddingTop: theme.spacing(1.75),
          paddingBottom: theme.spacing(1.75),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(23),
        }),
        sizeLarge: ({ theme }) => ({
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(0.75),
          background: theme.palette.neutral[20],
          color: theme.palette.neutral[100],
        }),
        sizeSmall: () => ({
          height: 30,
        }),
        sizeMedium: () => ({
          height: 32,
        }),
        clickable: ({ theme }) => ({
          "&:hover": {
            background: theme.palette.neutral[50],
          },
        }),
        colorPrimary: ({ theme }) => ({
          background: theme.palette.primary[40],
        }),
        outlined: ({ theme }) => ({
          background: "transparent",
          borderColor: theme.palette.neutral[90],
          color: theme.palette.neutral[50],
        }),
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.primary[40],
          color: theme.palette.primary[40],
        }),
        labelSmall: ({ theme }) => ({
          paddingLeft: theme.spacing(1.25),
          paddingRight: theme.spacing(1.25),
        }),
        labelMedium: ({ theme }) => ({
          paddingLeft: theme.spacing(2.125),
          paddingRight: theme.spacing(2.125),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          background: theme.palette.common.surface1,
        },
      }),
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.neutral[96],
        }),
        rounded: ({ theme }) => ({
          borderRadius: theme.spacing(3.75),
        }),
        outlined: ({ theme }) => ({
          background: theme.palette.neutral[100],
        }),
        elevation1: ({ theme }) => ({
          boxShadow: theme.shadows[0],
        }),
        elevation8: ({ theme }) => ({
          boxShadow: theme.shadows[1],
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: ({ theme }) => ({
          padding: theme.spacing(1, 1.5),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          padding: theme.spacing(2, 1.5),
          "&.MuiInputBase-inputSizeSmall": {
            padding: theme.spacing(1, 1.5),
          },
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          boxShadow: `0 0 0 1px ${theme.palette.neutral[90]}`,
          background: theme.palette.common.white,
          color: theme.palette.common.onBackground,
          transition: theme.transitions.create("box-shadow", {
            duration: theme.transitions.duration.short,
          }),

          ":hover": {
            backgroundColor: theme.palette.common.white,
          },

          ":before": {
            display: "none",
          },

          ":after": {
            display: "none",
          },

          "&.Mui-focused": {
            boxShadow: `0 0 0 2px ${theme.palette.secondary[80]}`,
            background: theme.palette.common.white,
          },

          "&.MuiInputBase-colorError": {
            boxShadow: `0 0 0 1px ${theme.palette.error[60]}`,
          },

          "&.Mui-disabled": {
            background: theme.palette.neutralVariant[95],
          },

          "&.Mui-error": {
            boxShadow: `0 0 0 2px ${theme.palette.error.main}`,
          },
        }),
        input: ({ theme }) => ({
          padding: theme.spacing(2, 1.5),
          background: "transparent",
          fontSize: "1rem",
          "&.MuiInputBase-inputSizeSmall": {
            padding: theme.spacing(1, 1.5),
          },
          "&:focus": {
            background: "transparent",
          },
          "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: theme.palette.neutral[60],
            opacity: 1,
          },
        }),
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        variant: "filled",
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: "relative",
          fontSize: "0.875rem",
          transform: "none",
          marginBottom: theme.spacing(0.5),
          color: theme.palette.common.onSurface,
          padding: theme.spacing(0, 1.5),
          "&.Mui-disabled": {
            color: theme.palette.common.onSurface,
          },
          "&.Mui-focused": {
            fontSize: "0.875rem",
            color: theme.palette.common.onPrimaryContainer,
          },
        }),
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          paddingRight: "0.75rem",
          paddingLeft: "0.75rem",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-active": {
            color: theme.palette.primary.main,
          },

          "&.Mui-completed": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.9375rem",
        },
        iconContainer: {
          "& .MuiStepIcon-root": {
            width: "2rem",
            height: "2rem",
          },
        },
      },
    },
    MuiStepper: {
      defaultProps: {
        connector: null,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "filled",
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headline1: "h1",
          headline2: "h2",
          headline3: "h3",
          headline4: "h4",
          headline5: "h5",
          headline6: "h6",
          text1: "p",
          text2: "p",
          text3: "p",
          text4: "p",
          text5: "p",
          text6: "p",
          text7: "p",
        } as Record<keyof TypographyPropsVariantOverrides, string>,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.neutral[100],
          boxShadow: "none",
          borderBottomWidth: 1,
          borderBottomColor: alpha(theme.palette.common.outline, 0.4),
          borderBottomStyle: "solid",
          "&:first-child": {
            borderRadius: 0,
          },
          "&:last-child": {
            borderRadius: 0,
            borderBottomWidth: 0,
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5, 2),
        }),
        content: ({ theme }) => ({
          "&.Mui-expanded": {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(0.5),
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 3, 3, 3),
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          backgroundColor: "#CCCCCC",
        },
        mark: {
          backgroundColor: "#CCCCCC",
          height: 8,
          width: 2,
        },
        markActive: {
          backgroundColor: "#FB7659",
        },
      },
    },
  },
});
