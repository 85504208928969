export const ExpandIconFAQ = () => (
  <svg
    width="15"
    height="3"
    viewBox="0 0 15 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.66699H0.999999"
      stroke="#343434"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
