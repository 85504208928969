import { ShopItem } from "@/shared/api/shops-api";

export enum ComponentType {
  MAIN_CALCULATOR = "bnpl.main-calculator",
  PRESENTATION_CARDS = "bnpl.presentation-cards",
  DESCRIPTION_CARDS = "bnpl.description-cards",
  SHOP_CATEGORIES = "bnpl.shop-categories",
  TESTIMONIALS = "bnpl.testimonials",
  DOWNLOAD_APP = "bnpl.download-app",
  FAQ = "bnpl.faq",
  MERCHANT_JUMBOTRON = "bnpl.merchant-jumbotron",
  STEPPER = "bnpl.stepper-bnpl",
  SIMPLE_BANNER = "bnpl.simple-banner",
  PAYMENT_PROCESS = "bnpl.payment-process",
  JUMBOTRON = "bnpl.jumbotron",
  STEPS_LIST = "bnpl.steps-list",
  PARTNERS_STORIES_SECTION = "bnpl.partner-stores-section",
}

export type PartnersStoresBlockType = {
  id: number;
  title: string;
  description?: string;
  image: IImage;
};

export type PartnersStoresSectionType = {
  id: number;
  title: string;
  stores: PartnersStoresBlockType[];
  __component: ComponentType.PARTNERS_STORIES_SECTION;
};

export type TMainCalculator = {
  id: number;
  __component: ComponentType.MAIN_CALCULATOR;

  appstoreLink?: string;
  description?: string;
  googlePlayLink?: string;
  title?: string;
  background?: IImage;
  calculator?: {
    buttonText?: string;
    defaultSum: number;
    description?: string;
    id: number;
    max: number;
    min: number;
    segment_1?: string;
    segment_2?: string;
    segment_3?: string;
    segment_4?: string;
    step: number;
    title?: string;
  };
};

export type PresentationCardItem = {
  backgroundDirection: "top" | "center" | "bottom";
  id: number;
  image?: IImage;
  title?: string;
};

export type TPresentationCards = {
  id: number;
  __component: ComponentType.PRESENTATION_CARDS;

  items?: PresentationCardItem[];
};

export type DescriptionCardItem = {
  badge?: IImage;
  comment?: string;
  description?: string;
  id: number;
  image?: IImage;
};

export type TwoTextAndImage = {
  text1: string;
  text2: string;
  image: IImage;
};

export type Jumbotron = {
  id: number;
  __component: ComponentType.JUMBOTRON;
  content: TwoTextAndImage;
  googlePlayLink: string;
  appStoreLink: string;
};

export type StepsList = {
  id: number;
  title: string;
  image: IImage;
  items: TwoTextAndImage[];
  __component: ComponentType.STEPS_LIST;
};

export type TDescriptionCards = {
  id: number;
  __component: ComponentType.DESCRIPTION_CARDS;

  title?: string;
  cards?: DescriptionCardItem[];
};

export type TShopCategories = {
  id: number;
  __component: ComponentType.SHOP_CATEGORIES;
  shops?: ShopItem[];
  title?: string;
};

export type TestimonialCard = {
  avatar?: IImage;
  id: number;
  name?: string;
  role?: string;
  text?: string;
};

export type TTestimonials = {
  id: number;
  __component: ComponentType.TESTIMONIALS;

  title?: string;
  cards?: TestimonialCard[];
};

export type TDownloadApp = {
  id: number;
  __component: ComponentType.DOWNLOAD_APP;

  image?: IImage;
  text_bottom?: string;
  text_top?: string;
  appstoreLink?: string;
  googlePlayLink?: string;
};

export type FAQItem = {
  id: number;
  answer?: string;
  question?: string;
};

export type TFaq = {
  id: number;
  __component: ComponentType.FAQ;

  title?: string;
  items?: FAQItem[];
};

export type TMerchantJumbotron = {
  id: number;
  __component: ComponentType.MERCHANT_JUMBOTRON;

  description?: string;
  image?: IImage;
  buttonText?: string;
  titleDesktop?: string;
  bottomTextMobile?: string;
  signupButtonText?: string;
};

export type TwoText = {
  id: number;
  text1?: string;
  text2?: string;
};

export type CardWithImageAndTwoText = {
  id: number;
  image: IImage;
  text1?: string;
  text2?: string;
};

export type CardWithImageAndOneText = {
  id: number;
  image: IImage;
  text?: string;
};

export type TStepperBnpl = {
  id: number;
  __component: ComponentType.STEPPER;

  stepperItem: TwoText[];
};

export type TSimpleBanner = {
  id: number;
  __component: ComponentType.SIMPLE_BANNER;

  title?: string;
  link?: {
    href: string;
    id: number;
    target: "_blank";
    text: "Connect";
  };
};

export type TPaymentProcess = {
  id: number;
  __component: ComponentType.PAYMENT_PROCESS;

  title?: string;
  items?: CardWithImageAndOneText[];
};

export type CMSBlock =
  | TMainCalculator
  | TPresentationCards
  | TDescriptionCards
  | TShopCategories
  | TTestimonials
  | TDownloadApp
  | TFaq
  | TMerchantJumbotron
  | TSimpleBanner
  | TStepperBnpl
  | TPaymentProcess
  | Jumbotron
  | StepsList
  | PartnersStoresSectionType;

export type ICommonApiData = any;

export interface ImageAttributes {
  alternativeText?: string | null;
  url: string;
  width: number;
  height: number;
}

export interface IImage {
  data: {
    attributes: ImageAttributes;
    id: number;
  } | null;
}

/**
 * Country code according to {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements ISO 3166}
 */
export const enum CountryCode {
  LK = "LK",
  MY = "MY",
  VN = "VN",
  IN = "IN",
  ID = "ID",
}

export const enum Locale {
  siLK = "si-LK",
  msMY = "ms-MY",
  viVN = "vi-VN",
  idID = "id-ID",
  en = "en",
}

export type TPageData<T> = {
  id: number;
  attributes: {
    locale?: string;
    seo?: TSEOComponentData;
  } & T;
};

export type TSEOMetaSocialItem = {
  description: string;
  id: number;
  image?: IImage | null;
  socialNetwork: string;
  title: string;
};

export type TSEOComponentData = {
  id: number;
  canonicalURL?: string;
  keywords?: string;
  metaDescription: string;
  metaImage: IImage | null;
  metaRobots?: string;
  metaSocial?: TSEOMetaSocialItem[];
  metaTitle: string;
  metaViewport?: string;
  structuredData?: Record<string, any>;
};
