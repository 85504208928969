import { Box, Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { GB, MY } from "country-flag-icons/react/3x2";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { MouseEvent, useState } from "react";

import { useConfig } from "@/shared/hooks/use-config";
import { Locale } from "@/shared/types";
import { IconArrowDropdown } from "@/shared/ui/icons";

type Props = {
  color?: string;
};

const flagProps = {
  width: 19,
};

const localesMap = [
  {
    label: "English",
    shortLabel: "EN",
    lang: Locale.en,
    icon: <GB {...flagProps} />,
  },
  {
    label: "Malay",
    shortLabel: "MY",
    lang: Locale.msMY,
    icon: <MY {...flagProps} />,
  },
];

export const LocaleSwitcher = (props: Props) => {
  const { locale, pathname, query } = useRouter();

  const appConfig = useConfig();

  const [menuAnchorEl, setMenuAnchorEl] = useState<
    null | Element | ((element: Element) => Element)
  >(null);
  const handleMenuOpen = (event: MouseEvent) =>
    setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleMenuSwitch = (event: MouseEvent) => {
    menuAnchorEl ? handleMenuClose() : handleMenuOpen(event);
  };

  const langs = localesMap.filter((item) =>
    appConfig?.locales.includes(item.lang)
  );

  const currentLocale = langs.find(({ lang }) => {
    return lang === locale;
  });

  const switcherButton = (
    <Button
      color="neutral"
      onClick={handleMenuSwitch}
      size="small"
      variant="text"
      endIcon={<IconArrowDropdown />}
      aria-controls={menuAnchorEl ? "lang-switcher-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={menuAnchorEl ? "true" : undefined}
      sx={{
        minWidth: 0,
        px: 1.5,

        "& .MuiButton-endIcon": {
          ml: 0,
          color: props.color || "inherit",
        },
      }}
    >
      <Typography variant="headline9" color={props.color || "inherit"}>
        {currentLocale?.shortLabel}
      </Typography>
    </Button>
  );

  if (langs?.length < 2) {
    return null;
  }

  return (
    <Box>
      {switcherButton}
      <Menu
        id="lang-switcher-menu"
        anchorEl={menuAnchorEl as any}
        onClose={handleMenuClose}
        open={!!menuAnchorEl}
      >
        {langs.map(({ label, lang, icon }) => (
          <MenuItem
            component={NextLink}
            href={{ pathname, query }}
            key={lang}
            locale={lang}
            dense
            onClick={handleMenuClose}
            selected={locale === lang}
            sx={{
              width: {
                xs: "auto",
              },
              px: { xs: 1, md: 2 },
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              textTransform="capitalize"
            >
              {icon}
              <Typography variant="text6">{label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
