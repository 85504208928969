// TODO: refactor to more unified code

export const masksByCountryCode = {
  ID: {
    landline: [
      "0",
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    mobile: (rawValue: string) => {
      if (rawValue === "0" || rawValue === "8" || rawValue === "08") {
        return [
          "0",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      return [
        "0",
        "8",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    },
  },
  LK: {
    landline: [
      "0",
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    mobile: (rawValue: string) => {
      if (rawValue === "0" || rawValue === "7" || rawValue === "07") {
        return [
          "0",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      return [
        "0",
        "7",
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    },
  },
  MY: {
    landline: [
      "0",
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    mobile: (rawValue: string) => {
      if (rawValue === "0" || rawValue === "1" || rawValue === "01") {
        return [
          "0",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      return [
        "0",
        "1",
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    },
  },
  VN: {
    landline: (rawValue: string) => {
      if (rawValue === "0" || rawValue === "2" || rawValue === "02") {
        return [
          "0",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      return [
        "0",
        "2",
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    },
    // TODO: more precise mask
    mobile: [
      "0",
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
  // TODO: more precise mask
  IN: {
    landline: (rawValue: string) => {
      if (rawValue === "+" || rawValue === "9" || rawValue === "+9") {
        return [
          "+",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      if (
        rawValue === "1" ||
        rawValue === "+1" ||
        rawValue === "+91" ||
        rawValue === "+91 "
      ) {
        return [
          "+",
          "9",
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      return [
        "+",
        "9",
        "1",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    },
    mobile: (rawValue: string) => {
      if (rawValue === "+" || rawValue === "9" || rawValue === "+9") {
        return [
          "+",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      if (
        rawValue === "1" ||
        rawValue === "+1" ||
        rawValue === "+91" ||
        rawValue === "+91 "
      ) {
        return [
          "+",
          "9",
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
      }

      return [
        "+",
        "9",
        "1",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    },
  },
};
