import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="12" viewBox="0 0 17 12" fill="none" {...props}>
      <path
        d="M16.5242 6.53034C16.8171 6.23744 16.8171 5.76257 16.5242 5.46968L11.7513 0.696707C11.4584 0.403813 10.9835 0.403813 10.6906 0.696707C10.3977 0.9896 10.3977 1.46447 10.6906 1.75737L14.9332 6.00001L10.6906 10.2426C10.3977 10.5355 10.3977 11.0104 10.6906 11.3033C10.9835 11.5962 11.4584 11.5962 11.7513 11.3033L16.5242 6.53034ZM0.265503 6.75001L15.9939 6.75001L15.9939 5.25001L0.265503 5.25001L0.265503 6.75001Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
