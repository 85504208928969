import { countryCode } from "@/shared/config";
import { CountryCode } from "@/shared/types";

export const COUNTRY: Record<CountryCode, boolean> = {
  ID: countryCode === CountryCode.ID,
  LK: countryCode === CountryCode.LK,
  MY: countryCode === CountryCode.MY,
  VN: countryCode === CountryCode.VN,
  IN: countryCode === CountryCode.IN,
};
