import { countryCode } from "@/shared/config";
import { CountryCode } from "@/shared/types";

import ammana from "./settings/ammana";
import ceyloan from "./settings/ceyloan";
import myvay from "./settings/myvay";
import tambadana from "./settings/tambadana";
import trustPaisa from "./settings/trustpaisa";
import { Config } from "./types";

const configByCountryCode: Record<CountryCode, Config> = {
  [CountryCode.LK]: ceyloan,
  [CountryCode.MY]: tambadana,
  [CountryCode.VN]: myvay,
  [CountryCode.IN]: trustPaisa,
  [CountryCode.ID]: ammana,
};

export const config: Config = configByCountryCode[countryCode];
