import { FocusEvent, forwardRef } from "react";
import MaskedInput from "react-text-mask";

import { Input, InputProps } from "../input";
import { masksByCountryCode } from "./constants";

export type InputPhoneProps = InputProps & {
  countryCode: "ID" | "LK" | "MY" | "VN" | "IN";
  type?: "landline" | "mobile";
};

export const InputPhone = forwardRef<HTMLDivElement, InputPhoneProps>(
  ({ countryCode, onBlur, onFocus, type = "mobile", value, ...props }, ref) => {
    const mask = masksByCountryCode[countryCode][type];

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
    };

    return (
      <MaskedInput
        guide={false}
        InputLabelProps={{ shrink: true }}
        mask={mask}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="tel"
        value={value}
        {...(props as any)}
        render={(maskRef, maskProps) => {
          return <Input {...maskProps} ref={ref} inputRef={maskRef} />;
        }}
      />
    );
  }
);

InputPhone.displayName = "InputPhone";
