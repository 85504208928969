import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconTwitter = (props: SvgIconProps) => {
  return (
    <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9824 26.7188L17.6106 13.0589L17.6266 13.0717L26.0767 3.28125H23.2529L16.3693 11.25L10.9028 3.28125H3.49708L12.2465 16.0345L12.2455 16.0334L3.01758 26.7188H5.84136L13.4943 17.853L19.5767 26.7188H26.9824ZM9.78398 5.41192L22.9332 24.5881H20.6955L7.53565 5.41192H9.78398Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
