import { FooterData } from "@/shared/api/common-api";
import { NavLink } from "@/shared/lib/navigations";

import { FooterBottom } from "./footer-bottom";
import { FooterTop } from "./footer-top";

export type FooterProps = {
  links: NavLink[];
  content: FooterData;
};

export const Footer = ({ links = [], content }: FooterProps) => {
  return (
    <>
      <FooterTop
        footerContact={content.footerContact}
        contactsTitle={content.contactsTitle}
        socialTitle={content.socialTitle}
        socialLinks={content.socialLinks}
        links={links}
        loginButton={content.loginButton}
        showAccountBtn={content.showAccountBtn}
      />
      <FooterBottom
        address={content.address}
        requisites={content.requisites}
        allRightsReserved={content.allRightsReserved}
      />
    </>
  );
};
