import { TSEOComponentData } from "@/shared/types";

export const populateSEO = {
  seo: {
    populate: ["metaSocial", "metaSocial.image", "metaImage"],
  },
};

export const mapSEOTags = (data: TSEOComponentData | undefined) => ({
  metaTitle: data?.metaTitle,
  keywords: data?.keywords,
  metaImage: data?.metaImage?.data?.attributes.url,
  metaViewport: data?.metaViewport || "width=device-width, initial-scale=1.0",
  canonicalURL: data?.canonicalURL,
  metaDescription: data?.metaDescription,
  metaRobots: data?.metaRobots,
});
