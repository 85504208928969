import { Config } from "../types";

const config: Config = {
  apiUrl: process.env.API_URL,
  currency: "Rp",
  defaultLocale: "id-ID",
  locales: ["id-ID"],
  cdnUrl: process.env.CDN_PATH,
  countryCode: process.env.COUNTRY_CODE,
  faviconUrl: `${process.env.CDN_PATH}/favicons/favicon.ico`,
  logoUrl: `${process.env.CDN_PATH}/logos/full.svg`,
  logoUrlLight: `${process.env.CDN_PATH}/logos/full-light.svg`,
  logoUrlShort: `${process.env.CDN_PATH}/logos/short.svg`,
  openGraphLogoUrl: `${process.env.CDN_PATH}/logos/openGraphLogo.png`,
  title: "Ammana",
  googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
  phonePrefixInternational: "+62",
  phonePrefixNational: "0",
  externalLinks: {
    googlePlayBadge:
      "https://play.google.com/store/apps/details?id=com.ammana.app&hl=ru&gl=US&pli=1",
    whatsApp: "",
    viber: "",
    payEmail: "borrower@ammana.id",
    supportEmail: "",
  },
  domain: "todo",
  bankAccountHolderName: "",
  chatbotClientKey: "",
};

export default config;
