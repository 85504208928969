import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { forwardRef } from "react";

export type InputProps = TextFieldProps;

export const Input = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
  return (
    <MuiTextField InputLabelProps={{ shrink: true }} {...props} ref={ref} />
  );
});

Input.displayName = "Input";
