import localFont from "next/font/local";

export const mont = localFont({
  src: [
    {
      path: "../../../assets/fonts/Mont/Mont-Regular.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../assets/fonts/Mont/Mont-SemiBold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../../assets/fonts/Mont/Mont-Bold.ttf",
      weight: "800",
      style: "normal",
    },
  ],
});
