import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import NextLink from "next/link";
import { usePathname } from "next/navigation";

import { NavLinkItem } from "@/shared/api/common-api";
import { useBoolean } from "@/shared/hooks";
import { LocaleSwitcher } from "@/shared/ui";
import { navigations } from "@/utils";

import { IconClose, IconMenu } from "../icons";
import { Logo } from "../logo";

export type LoginButtonProps = {
  variant: "desktop" | "mobile";
};

export const LoginButton = ({ variant }: LoginButtonProps) => {
  const pathname = usePathname();

  return pathname === "/merchants" ? (
    <Button
      fullWidth={true}
      // TODO после выделения для BNPL отдельной cms подумать над выносом в отдельное поле
      href="https://portal.ahapay.my/login"
      target="_blank"
      size="small"
      sx={{
        display: {
          xs: variant === "mobile" ? "block" : "none",
          md: variant === "desktop" ? "block" : "none",
        },
      }}
    >
      Login
    </Button>
  ) : null;
};

export type NavMenuProps = {
  links: NavLinkItem[];
  variant: "desktop" | "mobile";
};

export const NavMenu = ({ links, variant }: NavMenuProps) => {
  const pathname = usePathname();

  return (
    <Stack
      alignItems={{
        xs: "stretch",
        md: "center",
      }}
      component="nav"
      direction={{
        xs: "column",
        md: "row",
      }}
      display={{
        xs: variant === "mobile" ? "flex" : "none",
        md: variant === "desktop" ? "flex" : "none",
      }}
      divider={<Divider />}
      flex={{
        md: 1,
      }}
      justifyContent="center"
    >
      {links.map((link) => {
        const to = link.to === "home" ? "/" : `/${link.to}`;
        const isActive =
          pathname === to ||
          (to === navigations.merchants && pathname === navigations.signup);
        return (
          <MenuItem href={to} isActive={isActive}>
            {link.text}
          </MenuItem>
        );
      })}
    </Stack>
  );
};

export type DrawerMenuButtonProps = {
  links: NavLinkItem[];
};

const DrawerMenuButton = ({ links }: DrawerMenuButtonProps) => {
  const open = useBoolean();

  const handleOpenButtonClick = () => {
    open.toggle();
  };

  const handleDrawerClose = () => {
    open.off();
  };

  return (
    <>
      <IconButton
        onClick={handleOpenButtonClick}
        sx={{
          display: {
            md: "none",
          },
        }}
      >
        {open.value ? <IconClose /> : <IconMenu />}
      </IconButton>

      <Drawer
        anchor="right"
        hideBackdrop={true}
        onClose={handleDrawerClose}
        open={open.value}
        PaperProps={{
          sx: {
            width: "100%",
            bgcolor: "#ffffff",
            mt: "73px",
          },
        }}
      >
        <NavMenu links={links} variant="mobile" />

        <Box
          display={{
            xs: "block",
            md: "none",
          }}
          p={2}
        >
          <LoginButton variant="mobile" />
        </Box>
      </Drawer>
    </>
  );
};

export type MenuItemProps = {
  children: React.ReactNode | React.ReactNode[];
  href: string;
  isActive?: boolean;
};

export const MenuItem = ({
  children,
  href,
  isActive = false,
}: MenuItemProps) => {
  return (
    <Link
      borderRadius={{
        xs: 0,
        md: 0.5,
      }}
      component={NextLink}
      fontSize={{
        xs: 16,
        md: 14,
      }}
      href={href}
      py={{
        xs: 2.5,
        md: 0.5,
      }}
      px={1}
      textAlign={{ xs: "center", md: "left" }}
      bgcolor={isActive ? "#f5f5f5" : "transparent"}
      color={isActive ? "#fb7659" : "#000000"}
      sx={{
        cursor: isActive ? "default" : "pointer",
      }}
    >
      {children}
    </Link>
  );
};

export type HeaderProps = {
  navLinks: NavLinkItem[];
};

export const Header = ({ navLinks }: HeaderProps) => {
  return (
    <Container
      component="header"
      maxWidth="xl"
      sx={{
        position: "relative",
        py: 2,
        zIndex: 10000,
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <NextLink href="/">
          <Logo width="auto" />
        </NextLink>

        <NavMenu links={navLinks} variant="desktop" />

        <Stack alignItems="center" direction="row" spacing={1}>
          <LocaleSwitcher />
          <DrawerMenuButton links={navLinks} />
          <LoginButton variant="desktop" />
        </Stack>
      </Stack>
    </Container>
  );
};
