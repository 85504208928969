import qs from "qs";

export class ApiInstance {
  authorization: string;
  apiUrl: string;
  locale: string;

  constructor(locale = "si-LK") {
    this.authorization = `Bearer ${process.env.CMS_TOKEN}`;
    this.apiUrl = `${process.env.CMS_URL}/api`;
    this.locale = locale;
  }

  _Send(endpoint: string, options?: RequestInit) {
    return new Promise((resolve, reject) => {
      fetch(this.apiUrl + endpoint + `?locale=${this.locale}&populate=*`, {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: this.authorization,
        },
      })
        .then((res) => res.json())
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  }

  _SendWithQuery<T>(endpoint: string, query: any = {}) {
    const _query = qs.stringify(
      {
        locale: this.locale,
        ...query,
      },
      { addQueryPrefix: true, encodeValuesOnly: true }
    );

    const url = this.apiUrl + endpoint + _query;

    const options: RequestInit = {
      headers: {
        Authorization: this.authorization,
      },
    };

    return new Promise<T>((resolve, reject) => {
      fetch(url, options)
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
