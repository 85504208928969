import { Box, Typography } from "@mui/material";
import {
  AnchorHTMLAttributes,
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import ReactMarkdown from "react-markdown";
import { HeadingProps } from "react-markdown/lib/ast-to-react";
import rehypeRaw from "rehype-raw";

interface IMarkdownElements {
  A: (
    props: PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>
  ) => ReactElement;
  H2: (props: HeadingProps) => ReactElement;
}

const MarkdownElements: FC<{ children?: ReactNode }> & IMarkdownElements = ({
  children,
}) => <>{children}</>;

MarkdownElements.A = ({ children, href, target }) => (
  <Box
    {...{ href, target }}
    component="a"
    sx={{
      color: "secondary.dark",
      textDecoration: "underline",
      textDecorationColor: "rgba(20, 142, 0, 0.4)",
      transition: (theme) => `150ms ${theme.transitions.easing.easeIn}`,

      "&:hover": {
        textDecorationColor: "inherit",
      },
    }}
  >
    {children}
  </Box>
);

MarkdownElements.H2 = ({ children }) => (
  <Typography
    color="primary.light"
    fontWeight="700"
    variant="headline6"
    component="h2"
  >
    {children}
  </Typography>
);

export const Markdown = ({ content }: { content: string }) => (
  <Box
    sx={{
      "&, & *": {
        lineHeight: "28px",
      },

      "& img": {
        maxWidth: 1,
      },

      "& ul, & ol": {
        pl: 3,

        "li + li": {
          mt: 0.5,
        },
      },
    }}
  >
    <ReactMarkdown
      linkTarget="_blank"
      rehypePlugins={[rehypeRaw]}
      components={{
        a: MarkdownElements.A,
        h2: MarkdownElements.H2,
      }}
    >
      {content}
    </ReactMarkdown>
  </Box>
);
