import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconSubtract = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09135 0C2.03105 0 0.36084 1.66591 0.36084 3.72092V17.8604C0.36084 19.9154 2.03105 21.5813 4.09135 21.5813H18.2673C20.3276 21.5813 21.9978 19.9154 21.9978 17.8604V3.72092C21.9978 1.66591 20.3276 0 18.2673 0H4.09135ZM5.77268 12.3894C5.58604 13.5145 7.74564 14.8079 10.5963 15.2784C13.4469 15.7488 15.9091 15.2182 16.0958 14.0931C16.2824 12.968 14.1228 11.6745 11.2722 11.204C8.42153 10.7336 5.95932 11.2643 5.77268 12.3894ZM11.9127 10.4031C9.10964 11.1022 6.44864 10.1186 5.96924 8.20619C5.48984 6.2938 7.37358 4.17678 10.1767 3.47771C12.9798 2.77864 15.6408 3.76223 16.1202 5.67463C16.5996 7.58702 14.7159 9.70404 11.9127 10.4031ZM5.97629 17.1364C5.97629 17.6898 8.31861 18.1384 11.208 18.1384C14.0974 18.1384 16.4397 17.6898 16.4397 17.1364C16.4397 16.5831 14.0974 16.1345 11.208 16.1345C8.31861 16.1345 5.97629 16.5831 5.97629 17.1364Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
