import { Grid, Link } from "@mui/material";
import NextLink from "next/link";

import { NavLink } from "@/shared/lib/navigations";

export type FooterNavMenuProps = {
  links?: NavLink[];
};

export const FooterNavMenu = ({ links }: FooterNavMenuProps) => {
  if (!links) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      {links.map(({ href, text, target }, i) => (
        <Grid item xs={6} key={i}>
          <Link
            key={i}
            component={NextLink}
            variant="text6"
            href={href}
            target={target}
            color="common.white"
            sx={{
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            {text}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
