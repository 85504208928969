import { Config } from "../types";

const config: Config = {
  apiUrl: process.env.API_URL,
  currency: "Rm",
  defaultLocale: "ms-MY",
  locales: ["ms-MY", "en"],
  cdnUrl: process.env.CDN_PATH,
  countryCode: process.env.COUNTRY_CODE,
  faviconUrl: `${process.env.CDN_PATH}/favicons/favicon.ico`,
  logoUrl: `${process.env.CDN_PATH}/logos/full.svg`,
  logoUrlLight: `${process.env.CDN_PATH}/logos/full-light.svg`,
  logoUrlShort: `${process.env.CDN_PATH}/logos/short.svg`,
  openGraphLogoUrl: `${process.env.CDN_PATH}/logos/openGraphLogo.png`,
  title: "Ahapay",
  googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
  phonePrefixInternational: "+60",
  phonePrefixNational: "0",
  externalLinks: {
    // TODO: links for Ahapay
    googlePlayBadge:
      "https://play.google.com/store/apps/details?id=id.tambadana.app&pli=1",
    whatsApp: "#",
    viber: "#",
    payEmail: "pay@ahapay.my",
    supportEmail: "help@ahapay.my",
  },
  domain: "ahapay.my",
  bankAccountHolderName: "Wawasan Cojaya Sdn Bhd",
  chatbotClientKey: "2bd76165-6767-44e6-be31-2a7c7c49ac48",
};

export default config;
