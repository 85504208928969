import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconLocation = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M9 8.10001C9.29667 8.10001 9.58668 8.01203 9.83335 7.84721C10.08 7.68239 10.2723 7.44812 10.3858 7.17403C10.4994 6.89994 10.5291 6.59834 10.4712 6.30737C10.4133 6.0164 10.2704 5.74912 10.0607 5.53934C9.85088 5.32957 9.58361 5.1867 9.29264 5.12883C9.00166 5.07095 8.70006 5.10065 8.42597 5.21419C8.15189 5.32772 7.91762 5.51998 7.7528 5.76665C7.58797 6.01332 7.5 6.30333 7.5 6.60001C7.5 6.99783 7.65804 7.37936 7.93934 7.66066C8.22064 7.94197 8.60218 8.10001 9 8.10001ZM8.4675 13.2825C8.53722 13.3528 8.62017 13.4086 8.71157 13.4467C8.80296 13.4848 8.90099 13.5044 9 13.5044C9.09901 13.5044 9.19704 13.4848 9.28843 13.4467C9.37983 13.4086 9.46278 13.3528 9.5325 13.2825L12.6 10.2075C13.3125 9.49541 13.7977 8.58802 13.9945 7.6001C14.1912 6.61218 14.0905 5.58811 13.7051 4.65742C13.3197 3.72673 12.667 2.93124 11.8295 2.37155C10.992 1.81186 10.0073 1.51312 9 1.51312C7.99269 1.51312 7.008 1.81186 6.17048 2.37155C5.33297 2.93124 4.68025 3.72673 4.29489 4.65742C3.90953 5.58811 3.80883 6.61218 4.00555 7.6001C4.20226 8.58802 4.68753 9.49541 5.4 10.2075L8.4675 13.2825ZM5.4225 6.255C5.47374 5.72027 5.64376 5.20372 5.92014 4.74309C6.19652 4.28246 6.57229 3.88936 7.02 3.5925C7.60815 3.20633 8.2964 3.00057 9 3.00057C9.7036 3.00057 10.3918 3.20633 10.98 3.5925C11.4247 3.88834 11.7984 4.27906 12.074 4.73654C12.3497 5.19403 12.5206 5.70693 12.5744 6.23835C12.6282 6.76976 12.5635 7.3065 12.3851 7.80995C12.2067 8.3134 11.9189 8.77105 11.5425 9.15L9 11.6925L6.4575 9.15C6.08064 8.77473 5.7922 8.32015 5.61315 7.81936C5.43411 7.31856 5.36898 6.78414 5.4225 6.255ZM14.25 15H3.75C3.55109 15 3.36032 15.079 3.21967 15.2197C3.07902 15.3603 3 15.5511 3 15.75C3 15.9489 3.07902 16.1397 3.21967 16.2803C3.36032 16.421 3.55109 16.5 3.75 16.5H14.25C14.4489 16.5 14.6397 16.421 14.7803 16.2803C14.921 16.1397 15 15.9489 15 15.75C15 15.5511 14.921 15.3603 14.7803 15.2197C14.6397 15.079 14.4489 15 14.25 15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
