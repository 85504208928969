import { SvgIcon, SvgIconProps } from "@mui/material";

export const IconTiktok = (props: SvgIconProps) => {
  return (
    <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.893 4.04712H20.0682C20.3742 5.61914 21.3551 6.96806 22.7139 7.81082L22.7153 7.81244C23.6613 8.3989 24.7891 8.74106 25.9999 8.74106V9.65954L26 12.8665C23.751 12.8665 21.6669 12.182 19.9659 11.0205V19.4055C19.9659 23.5932 16.3848 27 11.9828 27C10.2819 27 8.70452 26.4897 7.40793 25.6236L7.40571 25.6214C5.34831 24.2463 4 21.9732 4 19.4047C4 15.2172 7.58111 11.8102 11.9831 11.8102C12.3483 11.8102 12.7063 11.8388 13.0588 11.8843V12.8618L13.0587 16.0973C12.7181 15.996 12.3582 15.937 11.9828 15.937C9.97254 15.937 8.33708 17.4931 8.33708 19.4055C8.33708 20.7373 9.13131 21.8944 10.2917 22.4753C10.7977 22.7287 11.3724 22.8739 11.9827 22.8739C13.9466 22.8739 15.5487 21.3877 15.6216 19.536L15.6285 3H19.9658C19.9658 3.35768 20.0021 3.70726 20.0682 4.04712H16.893Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
