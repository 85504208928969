import { Button, ButtonProps } from "@mui/material";
import Image from "next/image";

import appStoreLogo from "@/assets/images/app-store.svg";
import googlePlayLogo from "@/assets/images/google-play.svg";

type DownloadAppButtonProps = {
  brand: "AppStore" | "GooglePlay";
  href: ButtonProps["href"];
};

export const DownloadAppButton = ({ brand, href }: DownloadAppButtonProps) => {
  const logoImagesMap: Record<DownloadAppButtonProps["brand"], string> = {
    AppStore: appStoreLogo,
    GooglePlay: googlePlayLogo,
  };

  const src = logoImagesMap[brand];

  return (
    <Button
      href={href}
      sx={{
        bgcolor: "common.black",
        borderRadius: 100,
        height: 53,
        display: "inline-flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Image src={src} alt="Download app" />
    </Button>
  );
};
