import { Stack, Typography } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";

interface ISectionProps {
  title: ReactNode;
  titleWidth: number;
  description?: ReactNode;
  descriptionWidth?: number;
}

export const Section = ({
  children,
  title,
  titleWidth,
  description,
  descriptionWidth,
}: PropsWithChildren<ISectionProps>) => (
  <Stack component="section">
    <Stack alignItems="center" px={{ xs: "30px", md: 0 }}>
      <Typography
        color="text.primary"
        display="inline"
        fontWeight="700"
        fontSize={{ xs: "26px", md: "32px" }}
        lineHeight={{ xs: "32px", md: "40px" }}
        letterSpacing={{ xs: "-1px", md: 0 }}
        textAlign="center"
        variant="headline4"
        width={{ xs: 1, md: titleWidth }}
      >
        {title}
      </Typography>
    </Stack>

    {!!description && (
      <Stack
        margin="auto"
        maxWidth={{ xs: 1, md: descriptionWidth }}
        mt={3}
        px={{ xs: "30px", md: 0 }}
      >
        <Typography color="text.secondary" textAlign="center">
          {description}
        </Typography>
      </Stack>
    )}

    <Stack mt="70px">{children}</Stack>
  </Stack>
);
