import TagManager from "react-gtm-module";

const init = ({ id }: { id?: string }) => {
  if (id) {
    TagManager.initialize({
      gtmId: id,
    });
  }
};

export const GoogleTagManager = {
  init,
};
