import { Container, ContainerProps } from "@mui/material";

export const SectionContainer = (props: ContainerProps) => {
  const { sx, children, ...otherProps } = props;

  return (
    <Container
      component="section"
      sx={{
        my: {
          xs: 7.5,
          md: 10,
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Container>
  );
};
